var color_map = {
    her: {
        primary: '#ED217C',
        danger: '#a93aba'
    },
    him: {
        primary: '#00aeef',
        danger: '#379e9e'
    },
    get_highlighted: {
        primary: '#C4D800',
        danger: '#7da700'
    },
    light: '#cad3df',
    muted: '#eceff4',
    warning: '#66f',
    dark: '#54657e',
    twentyb: '#ccc',
    fourtyb: '#999',
    sixtyb: '#666',
    eightyb: '#333',
    black: '#000'
}

exports.getColor = function(color_key, palette) {
    if (palette && color_map[palette].hasOwnProperty(color_key)) {
        return color_map[palette][color_key];
    } else {
        return color_map[color_key];
    }
};
