var regionSelect = function() {
    var regions = {};
    regions['Central'] = ['AR', 'LA', 'OK', 'TX', 'IA', 'KS', 'MN', 'MO', 'NE', 'ND', 'SD', 'IL', 'IN', 'MI', 'OH', 'WI', 'AL', 'KY', 'MS', 'TN']
    regions['East Coast'] = ['ME', 'NH', 'MA', 'RI', 'CT', 'NY', 'NJ', 'DE', 'MD', 'VA', 'NC', 'SC', 'GA', 'FL', 'VT', 'PA', 'DC', 'WV'];
    regions['Eastern'] = ['ME', 'NH', 'MA', 'RI', 'CT', 'NY', 'NJ', 'DE', 'MD', 'VA', 'NC', 'SC', 'GA', 'FL', 'VT', 'PA', 'DC', 'WV', 'OH', 'MI', 'IN', 'KY', 'TN', 'AL', 'MI'];
    regions['Atlantic'] = ['ME', 'NH', 'MA', 'RI', 'CT', 'NY', 'NJ', 'DE', 'MD', 'VA', 'NC', 'SC', 'GA', 'FL'];
    regions['East North Central'] = ['IL', 'IN', 'MI', 'OH', 'WI'];
    regions['East South Central'] = ['AL', 'KY', 'MS', 'TN'];
    regions['Four Corners'] = ['CO', 'NM', 'AZ', 'UT'];
    regions['Great Plains'] = ['IA', 'KS', 'MN', 'MO', 'NE', 'ND', 'SD'];
    regions['Mideast'] = ['DE', 'DC', 'MD', 'NJ', 'NY', 'PA'];
    regions['Midwest'] = ['IL', 'IN', 'MI', 'OH', 'WI', 'IA', 'KS', 'MN', 'MO', 'NE', 'ND', 'SD'];
    regions['Mountain'] = ['AZ', 'CO', 'ID', 'MT', 'NV', 'NM', 'UT', 'WY'];
    regions['Northwest'] = ['OR', 'WA', 'ID', 'MT', 'WY'];
    regions['Northeast'] = ['CT', 'ME', 'MA', 'NH', 'RI', 'VT', 'NJ', 'NY', 'PA'];
    regions['Pacific'] = ['AK', 'CA', 'HI', 'OR', 'WA'];
    regions['South Atlantic'] = ['DE', 'FL', 'GA', 'MD', 'NC', 'SC', 'VA', 'DC', 'WV'];
    regions['South Central'] = ['AR', 'LA', 'OK', 'TX'];
    regions['South'] = ['DE', 'FL', 'GA', 'MD', 'NC', 'SC', 'VA', 'DC', 'WV', 'AL', 'KY', 'MS', 'TN', 'AR', 'LA', 'OK', 'TX'];
    regions['West Coast'] = ['AK', 'CA', 'HI', 'NV', 'OR', 'WA'];
    regions['West North Central'] = ['IA', 'KS', 'MN', 'MO', 'NE', 'ND', 'SD'];
    regions['West South Central'] = ['AR', 'LA', 'OK', 'TX'];

    regions['Great Lakes'] = ['IL', 'IN', 'MI', 'OH', 'WI'];
    regions['Mid Atlantic'] = ['DC', 'DE', 'MD', 'NJ', 'NY', 'PA'];
    regions['New England'] = ['CT', 'MA', 'ME', 'NH', 'RI', 'VT'];
    regions['Plains'] = ['IA','KS','MN','MO','ND','NE','SD'];
    regions['Rocky Mountain'] = ['CO', 'ID', 'MT', 'UT', 'WY'];
    regions['Southeast'] = ['AL', 'AR', 'FL', 'GA', 'KY', 'LA', 'MS', 'NC', 'SC', 'TN', 'VA', 'WV'];
    regions['Southwest'] = ['AZ', 'NM', 'OK', 'TX'];
    regions['West'] = ['AK', 'CA', 'HI', 'NV', 'OR', 'WA'];


    var apply_classes = function(){
        $('.college_filter_regions').each(function (ind, element) {
            let reg = $(element).data('value')
            if (element.checked) {
                if (regions[reg].length > 0) {
                    regions[reg].forEach(function(v, i) {
                        $('#'+ v).addClass('selected');
                    });
                }
            }
        });
    }

    $('.college_filter_regions').on('change', function() {
        $('.region-state').removeClass('selected');
        $('.new_athlete_filter .required').each(function(i, elem) {
            $(elem).removeClass('required');
        });
        $('.new_college_filter .required').each(function(i, elem) {
            $(elem).removeClass('required');
        });
        apply_classes();
    } );

    apply_classes();
};

$(document).ready(regionSelect);
document.addEventListener('turbo:load', regionSelect);