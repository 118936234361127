var positionSelects = function () {
    $('#primarySport').on('change', function () {
        $.ajax({
            url: '/positions/find',
            data: {'sport_id': this.value},
            success: updateSelects,
            dataType: 'json'
        });
    });
};

var updateSelects = function (data) {
    positions = ['<option value>Select your Primary Position</option>'];
    data.forEach(position => {
        positions.push("<option value=\"" + position.id + "\">" + position.name + "</option>");
    });
    if (data.length > 0) {
        $('.billing_form_position_id').removeClass('d-none');
        $('.athlete_position_id').removeClass('d-none');
        $('.athlete_secondary_position_id').removeClass('d-none');

        $('[data-primaryPos]').find('option').remove().end().append(positions.join('')).val();
        $('#billing_form_position_id').find('option').remove().end().append(positions.join('')).val();
        positions[0] = '<option value>Select your Secondary Position</option>';
        $('[data-secondaryPos]').find('option').remove().end().append(positions.join('')).val();
    } else {
        $('#billing_form_position_id').find('option').remove();
        $('.billing_form_position_id').addClass('d-none');

        $('#athlete_position_id').find('option').remove();
        $('.athlete_position_id').addClass('d-none');

        $('#athlete_secondary_position_id').find('option').remove();
        $('.athlete_secondary_position_id').addClass('d-none');
    }
}

$(document).ready(positionSelects);
document.addEventListener('turbo:load', positionSelects);