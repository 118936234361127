$(document).on("turbo:before-cache", function() {
  $('.select2-hidden-accessible').select2('destroy');
  $('.select2-hidden-accessible').off('select2:select');
});

$(document).on('turbo:load cocoon:after-insert', function() {
  $('select[data-uses-select2]').each(function () {
    var $node = $(this);

    var placeholder = $node.attr('placeholder') || null;

    $node.select2({
      theme: 'bootstrap4',
      width: '100%',
      placeholder: placeholder
    });
      
  });
});