import { getColor } from './color_map.js'; 

var charts = function() {
    var charts = $('.chart')

    charts.each(function(index) {
        createChart(this);
    });
};
var add = function(a, b) {
    return a + b;
}

var createChart = function(canvas) {
    var data = canvas.dataset;
    var datasets = data.datasets;
    
    var values = [];
    var bg_colors = [];
    var border_colors =  [];
    var title = ''; 
    var cutout = null;
    var start = null;
    var sweep = null;
    var chart_type = 'bar';
    var labels = [];
    var show_legend = true;
    var palette = null;
    var chart_datasets = null;

    if (data.chartType) {
        chart_type = data.chartType;
    }

    if (data.cutout) {
        cutout = data.cutout;
    } else {
        if (chart_type === 'doughnut') {
            cutout = 50;
        } else if (chart_type === 'pie') {
            cutout = 0;
        }
    }

    if (chart_type === 'gauge') {
        start = -1 * Math.PI;
        sweep = Math.PI;
        chart_type = 'doughnut';
    } else if (chart_type === 'pie' || chart_type === 'doughnut') {
        start = -0.5 * Math.PI;
        sweep = 2 * Math.PI;
    }

    if (data.palette) {
        palette = data.palette;
    }

    if (datasets) {
        datasets = JSON.parse(datasets);
        chart_datasets = [];

        datasets.forEach(dataset => {
            values = []

            if (dataset.values) {
                values = dataset.values.split(',').map(val => parseInt(val));
        
                if (dataset.max && (chart_type === 'doughnut' || chart_type === 'pie')) {
                    values.push(dataset.max - values.reduce(add, 0));
                }
            }

            bg_colors = [];
            border_colors = [];

            if (dataset.bgColor) {
                bg_colors = dataset.bgColor.split(',').map(color => getColor(color, palette))
            }
        
            if (data.borderColor) {
                border_colors = dataset.borderColor.split(',').map(color => getColor(color, palette))
            }

            chart_datasets.push({
                'label': dataset.label,
                'backgroundColor': bg_colors,
                'borderColor': border_colors,
                'data': values
            });
        });
    } else {
        if (data.bgColor) {
            bg_colors = data.bgColor.split(',').map(color => getColor(color, palette));
        }
    
        if (data.borderColor) {
            border_colors = data.borderColor.split(',');
        }

        if (data.title) {
            title = data.title;
        }

        if (data.values) {
            values = data.values.split(',').map(val => parseInt(val));
    
            if (data.max && (chart_type === 'doughnut' || chart_type === 'pie')) {
                values.push(data.max - values.reduce(add, 0));
            }
        }
    }

    if (data.labels) {
        labels = data.labels.split(',');
    }

    if (data.showLegend) {
        show_legend = data.showLegend === 'true';
    }

    if (datasets) {
        var chart = new Chart(canvas, {
            type: chart_type,
            data: {
                labels: labels,
                datasets: chart_datasets
            }, 
            options: {
                title: {
                    display: false,
                    text: ''
                },
                tooltips: {
                    enabled: false
                },
                events: [],
                legend: {
                    display: show_legend
                },
                cutoutPercentage: cutout,
                rotation: start,
                circumference: sweep
            }
        });
    } else {
        if (chart_type === 'horizontalBar') {
            var chart = new Chart(canvas, {
                type: chart_type,
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: title,
                            backgroundColor: bg_colors,
                            borderColor: border_colors,
                            data: values
                        }
                    ]
                }, 
                options: {
                    title: {
                        display: false,
                        text: ''
                    },
                    tooltips: {
                        enabled: false
                    },
                    events: [],
                    legend: {
                        display: show_legend
                    },
                    cutoutPercentage: cutout,
                    rotation: start,
                    circumference: sweep,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false,
                                max: 100
                            },
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 100
                            }
                        }]
                    }
                }
            });
            
        } else {
            var chart = new Chart(canvas, {
                type: chart_type,
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: title,
                            backgroundColor: bg_colors,
                            borderColor: border_colors,
                            data: values
                        }
                    ]
                }, 
                options: {
                    title: {
                        display: false,
                        text: ''
                    },
                    tooltips: {
                        enabled: false
                    },
                    events: [],
                    legend: {
                        display: show_legend
                    },
                    cutoutPercentage: cutout,
                    rotation: start,
                    circumference: sweep
                }
            });
        } 
    }
}
var timer_form_timeout = [];

$(document).ready(charts);
document.addEventListener('turbo:load', charts);

window.addEventListener('resize', function() {
    let th = this;
    clearTimeout(timer_form_timeout[th]);
    timer_form_timeout[th] = setTimeout(function () {
        console.log(Date.now());
        charts();
    }, 400);
}, true);
