let favorite_colleges = function() {
    $.each($('[data-favorite-college]'), function(index, element) {
        $(element).on('click', function() {
            $node = $(this);
            let collegeId = $node.attr('data-favorite-college');
            let data = {college_id: collegeId};
            ajaxCall('/favorited_user_college/toggle', data, $node);
        });
    });
};

let favorite_athletes = function (){
    $.each($('[data-favorite-athlete]'), function(index, element){
        $(element).on('click', function(){
            $node = $(this);
            let athleteId = $node.attr('data-favorite-athlete');
            let data = {athlete_id : athleteId};
            ajaxCall('/favorited_user_athlete/toggle', data, $node);
        });
    })
}

let toggle_favorite_star = function(node) {
    let yesNode = node.find('.is-favorite');
    let noNode = node.find('.not-favorite');
    $(yesNode).toggle();
    $(noNode).toggle();
}

let ajaxCall = function(url, data, node){
    $.ajax({
        type: 'post',
        url: url,
        data: data,
        dataType: 'json',
        success: function(data){
            toggle_favorite_star(node);
        },
        error: function (data){
            console.log(data.toString() + "Error")
        }
    });
}

$(document).ready(function(){
    favorite_colleges();
    favorite_athletes();
});

