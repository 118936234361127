$(document).on('ready turbo:load', function () {
    $('.datepicker').datepicker();
    $('.datepicker_us').datepicker({
        format: 'mm/dd/yyyy'
    });


    function is_exist() {
        const new_name = document.getElementById("filter_name").value;
        const type_filter = document.getElementById("type_filter").value;
        var val = false;
        $.ajax({
            type: "GET",
            cache: false,
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
            },
            url: '/custom_filters/exist_name',
            async: false,
            data: {new_name: new_name, type_filter: type_filter},
            success: function (response) {
                console.log('response=', response);
                val = response
                return response;
            },
            error: function (error) {
                console.log('error', error);
            }
        });

        console.log('val=', val);
        return val;
    }

    $('#custom_filters_form').on('submit', function () {
        const is_exist_to_backend = is_exist();
        console.log('is_exist_to_backend', is_exist_to_backend);

        if (is_exist_to_backend) {
            if (confirm('A saved search with this name already exists. Hit (Ok) to overwrite existing search, or hit (Cancel) to rename and save.')) {
                return true
            } else {
                return false
            }
        }

        return true
    });

    $("body").tooltip({ selector: '[data-toggle=tooltip]' });
});

$(document).on('change', '.state_search', function (event) {
    $('.new_athlete_filter .required').each(function(i, elem) {
        $(elem).removeClass('required');
    });
    $('.new_college_filter .required').each(function(i, elem) {
        $(elem).removeClass('required');
    });
});

$(document).on('change', '.type_search', function (event) {
    const type_search = $(this).val();
    if (type_search === '') { return }

    $('.type_search_item').addClass('d-none');

    if (type_search === 'Region') { $('.select-region').removeClass('d-none') }
    if (type_search === 'City') { $('.select-city').removeClass('d-none') }
    if (type_search === 'Zip Code') { $('.select-zip').removeClass('d-none') }
    if (type_search === 'State') { $('.select-state').removeClass('d-none') }

    $('.new_athlete_filter .required').each(function(i, elem) {
        $(elem).removeClass('required');
    });
    $('.new_college_filter .required').each(function(i, elem) {
        $(elem).removeClass('required');
    });
});

$(document).on('input', '.i_required', function (event) {
    if ($(this).val() === '') {
        $(this).addClass('required');
        $(this).prev().addClass('required');
    } else {
        $(this).removeClass('required');
        $(this).prev().removeClass('required');
    }
});

$(document).on('change', 'input.required', function (event) {
    console.log('input.required=', $(this).val())
});

$(document).on("turbo:load", function() { $('.selectpicker').selectpicker(); });
$(document).on("turbo:before-cache", function() {
    var remove1 = $('.bootstrap-select.type_search');
    $(remove1).replaceWith($(remove1).contents('.selectpicker'));

    var remove2 = $('.bootstrap-select.state_search');
    $(remove2).replaceWith($(remove2).contents('.selectpicker'));

    var remove3 = $('.bootstrap-select.city_search');
    $(remove3).replaceWith($(remove3).contents('.selectpicker'));

});

$(document).on('shown.bs.collapse', '.card-body', function (event) {
    const form_data = {
        user_id_from: $(this).attr('data-user-from-id'),
        user_ids_to: $(this).attr('data-user-to-ids'),
        college_ids: $(this).attr('data-college-ids')
    }

    $.ajax({
        type: "POST",
        cache: false,
        beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
        url: '/users/open_profile',
        async: false,
        data: form_data,
        error: function (error) {
            console.log('error', error);
        }
    });
});
